import React, { useContext } from "react";
import {
  AppBar as MaterialAppBar,
  Toolbar,
  Typography,
  AppBarProps,
  Link,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { UserContext } from "../authentication";
import { useHistory } from "react-router-dom";
import appConfig from "../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    left: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flexGrow: 1,
      cursor: "pointer",
    },
    title: {
      marginLeft: theme.spacing(2),
      fontSize: 20,
      color: theme.palette.navBar.contrastText,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.navBar.main
    },
    headerRight: {
      display: "flex",
      flexDirection: "row",
    },
    circle: {
      color: theme.palette.navBar.contrastText,
    },
    bg: {
      color: theme.palette.navBar.contrastText,
    },
    login: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: theme.spacing(2),
    },
    logo: {
      width: 80,
    },
  })
);

const AppBar = () => {
  const authentication = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();

  // Right menu logic
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <MaterialAppBar
        classes={{ colorPrimary: classes.bg }}
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <Link
            underline="none"
            onClick={() => history.push("/offers")}
            color="textPrimary"
            variant="h6"
            className={classes.left}
          >
            {appConfig.brand.logo && <img className={classes.logo} src={appConfig.brand.logo}  alt="logo"/>}
            <h1 className={classes.title}>Dashboard</h1>
          </Link>
          <div className={classes.headerRight}>
            {authentication && authentication.user && (
              <Typography className={classes.login} variant="body1">
                {authentication.user.name}
              </Typography>
            )}
            <IconButton color="primary" aria-label="user" onClick={handleClick}>
              <AccountCircle className={classes.circle} />
            </IconButton>
          </div>
        </Toolbar>
      </MaterialAppBar>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          // disabled={index === 0}
          onClick={() => authentication && authentication.logOut()}
        >
          Se déconnecter
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppBar;
