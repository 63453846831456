import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Toolbar } from "@material-ui/core";
import ArticleForm from "../shared/ArticleForm";
import Notifications from "../shared/Notifications";
import TabPanel from "../shared/TabPanel";
import appConfig from "../../appConfig";
import { FormattedMessage } from "react-intl";
require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      color: theme.palette.tab.main
    },

  })
);

const ArticleCreate = ({
  collection,
}: {
  collection: "offers" | "services" | "stories" | "locationNews";
}) => {
  const classes = useStyles();

  const title = "News d'établissement";

  const [tab, setTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div className={classes.tab}>
      <Toolbar />
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label={`${title} / Création`} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <ArticleForm action="create" />
        <Notifications online={false} />
      </TabPanel>
    </div>
  );
};
// ArticleCreate.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default ArticleCreate;
