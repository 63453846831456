import { Button, CircularProgress, Paper, Theme, Toolbar, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import appConfig from "../../appConfig";
import { useUserContext } from "../../authentication";
import Loader from "../shared/Loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(3),
    },
    element: {
      padding: theme.spacing(3),
    },
    loader: {
      marginRight: theme.spacing(2),
    },
  })
);

function Reporting() {
  const classes = useStyles();
  const authentication = useUserContext();
  const today = moment().format("L")
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState("")

  const downloadCSV = async (endpoint: string, fileName: string) => {
    if (authentication.accessToken) {
      setIsLoading(true)
      setFileName(fileName)

      fetch(endpoint, {
        headers: {
          Authorization: authentication.accessToken,
          contentType: "application/vnd.ms-excel"
        },
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${fileName}-${today}.xlsx`,
          );
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link?.parentNode?.removeChild(link);
          setIsLoading(false)

        });

    }
  };

  return (
    <>
      <Toolbar />
      <Paper className={classes.paper}>
        <div className={classes.element}>
          <Typography variant="h6">{appConfig.sections.offers}</Typography>

          <Button
            onClick={() => downloadCSV(`${appConfig.API}/reportings/offers_xlsx.xlsx`, 'bons plans')}
            color="secondary"
            variant="contained"
            disabled={isLoading && fileName === "bons plans"}
          >
            {isLoading && fileName === "bons plans" && <CircularProgress className={classes.loader} size={20} />}
            <FormattedMessage
            id="INDEX.BTN_TELECHARGER_FILE"
            defaultMessage="Télécharger le fichier"/>
          </Button>
        </div>
        <div className={classes.element}>
          <Typography variant="h6">
              <FormattedMessage
              id="INDEX.NAME_ETABLISS"
              defaultMessage="Établissements"/>
          </Typography>
          <Button
            onClick={() => downloadCSV(`${appConfig.API}/reportings/products_xlsx.xlsx`, "établissements")}
            color="secondary"
            variant="contained"
            disabled={isLoading && fileName === "établissements"}
          >
            {isLoading && fileName === "établissements" && <CircularProgress className={classes.loader} size={20} />}
            <FormattedMessage
            id="INDEX.BTN_TELECHARGER_FILE2"
            defaultMessage="Télécharger le fichier"/>
          </Button>
        </div>
      </Paper>
    </>
  );
}

export default Reporting;