import zamaniLogo from "./assets/zamani-logo.png";
import mtnLogo from "./assets/mtn-web-logo.png";
import mntFavicon from "./assets/mtn-favicon.png";
import laserLogo from "./assets/laser-logo.png";

const base = {
  locale: "fr",
  colors: {
    primary: "#222222", // bcp de trucs par défaut (indicateurs, menus, liens) + quelques trucs à nous (indicateur de tab)
    navBarBg: "#222222",
    navBarFont: "#999999",
    btnBg: "#337aff",
    btnFont: "#ffffff",
    tabFont: "#337aff",
    accent: "#337aff",
  },
  brand: {
    name: "Lasercats",
    logo: laserLogo,
    favicon: laserLogo,
  },
  fonts: {
    familyText: "Avenir Light",
    familyHeading: "Avenir Black",
  },
  sections: {
    services: "Services",
    offers: "Deals",
    news: "Actu",
    directory: "Magasins",
  },
  categories_order: ["directory", "offers", "services", "stories"],
  API: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-base.herokuapp.com"
  }/api/v2`,
  website_url: "https://selfcare-website-base.herokuapp.com/",
  googlePlacesKey: "AIzaSyB0QdBsX3GcGZWNUHtasud9dGux_KSVTMw",
  countryCode: "fr",
  users: true,
  default_payment_wording: {
    introduction:
      "Le numéro MoMoPay a utiliser pour votre paiement est le 91401414",
    momo_transaction_id: "Code MomoPay",
    momo_phone_used: "N° de téléphone de la transaction",
  },
  firebaseConfig: {
    apiKey: "AIzaSyC2WlPGr9cW6fiCiA_91gVDCFYWgV4Jr9U",
    authDomain: "selfcare-dev-4f0c2.firebaseapp.com",
    projectId: "selfcare-dev-4f0c2",
    storageBucket: "selfcare-dev-4f0c2.appspot.com",
    messagingSenderId: "240327210023",
    appId: "1:240327210023:web:ac432ceb7f44594adc4a02",
    measurementId: "G-FDQCVLW81F",
  },
};

const zamani = {
  locale: "fr",
  deeplinkPrefix: "zamstore://",
  colors: {
    primary: "#337aff", // bcp de trucs par défaut (indicateurs, menus, liens) + quelques trucs à nous (indicateur de tab)
    navBarBg: "#fff517",
    navBarFont: "#De00ff",
    btnBg: "#337aff",
    btnFont: "#ffffff",
    tabFont: "#337aff",
    accent: "#337aff",
  },
  brand: {
    name: "Zamani",
    logo: zamaniLogo,
    favicon: zamaniLogo,
  },
  fonts: {
    familyText: "Avenir Light",
    familyHeading: "Avenir Black",
  },
  sections: {
    services: "Services",
    offers: "Bons Plans",
    news: "Actualités",
    directory: "Annuaire",
  },
  // categories_order: ['offers', 'services', 'stories', 'directory'],
  API: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-zamani.herokuapp.com"
  }/api/v2`,
  website_url: "https://mtn-yellobiz-selfcare.innovacontents.com",
  googlePlacesKey: "AIzaSyB0QdBsX3GcGZWNUHtasud9dGux_KSVTMw",
  countryCode: "ne",
  currency: "XOF",
  users: false,
  default_payment_wording: {
    introduction: "",
    momo_transaction_id:
      "Référence de compte client",
    momo_phone_used: "Numéro de la facture",
  },
  firebaseConfig: {
    apiKey: "AIzaSyB7_W9Bly4KN_OO03QzNCgLE8-B-VzmoE8",
    authDomain: "selfcare-zamani.firebaseapp.com",
    projectId: "selfcare-zamani",
    storageBucket: "selfcare-zamani.appspot.com",
    messagingSenderId: "44642644510",
    appId: "1:44642644510:web:c48c519abe9ede38a7b40f",
    measurementId: "${config.measurementId}",
  },
};

const mtnZambia = {
  locale: "en",
  deeplinkPrefix: "yellobiz-zambia://",
  colors: {
    primary: "#FFCC00",
    navBarBg: "#FFCC00",
    navBarFont: "#292929",
    btnBg: "#FFCC00",
    btnFont: "#292929",
    tabFont: "#292929",
    accent: "#FFCC00",
  },
  brand: {
    name: "MTN Zambia",
    logo: mtnLogo,
    favicon: mntFavicon,
  },
  fonts: {
    familyText: "Avenir Light",
    familyHeading: "Avenir Black",
  },
  sections: {
    offers: "Best Deals",
    services: "Services",
    news: "News",
    directory: "Directory",
  },
  API: `${
    process.env.REACT_APP_API_URL ||
    "https://selfcare-backend-zambia.herokuapp.com"
  }/api/v2`,
  website_url: "https://mtnzambia-selfcare.innovacontents.com",
  googlePlacesKey: "AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk",
  countryCode: "zm",
  currency: "ZMW",
  users: true,
  default_payment_wording: {
    introduction: "MoMoPay number : 0967302074",
    momo_transaction_id: "MomoPay code",
    momo_phone_used: "Phone number used for transaction",
  },
  firebaseConfig: {
    apiKey: "AIzaSyBQa6xfuHuBUhQPOOjN9RbJpWoaTC71hQQ",
    authDomain: "selfcare-zambie.firebaseapp.com",
    projectId: "selfcare-zambie",
    storageBucket: "selfcare-zambie.appspot.com",
    messagingSenderId: "943194431053",
    appId: "1:943194431053:web:e8028415db813df4970604",
    measurementId: "G-SL8MQN2XNG",
  },
};

const mtnBenin = {
  locale: "fr",
  deeplinkPrefix: "yellobiz://",
  colors: {
    primary: "#FFCC00",
    navBarBg: "#FFCC00",
    navBarFont: "#292929",
    btnBg: "#FFCC00",
    btnFont: "#292929",
    tabFont: "#292929",
    accent: "#FFCC00",
  },
  brand: {
    name: "MTN Bénin",
    logo: mtnLogo,
    favicon: mntFavicon,
  },
  fonts: {
    familyText: "Avenir Light",
    familyHeading: "Avenir Black",
  },
  sections: {
    offers: "Bons Plans",
    services: "Services",
    news: "Actualités",
    directory: "Annuaire",
  },
  API: `${
    process.env.REACT_APP_API_URL ||
    "https://mtn-selfcare-production.herokuapp.com"
  }/api/v2`,
  website_url: "https://mtn-yellobiz-selfcare.innovacontents.com",
  googlePlacesKey: "AIzaSyCmET8VLakZLLT5XUa86P2XAtAyGGWMDUk",
  currency: "XOF",
  countryCode: "bj",
  users: true,
  default_payment_wording: {
    introduction:
      "Numéro MoMoPay : 91401414",
    momo_transaction_id: "Code MomoPay",
    momo_phone_used: "N° de téléphone de la transaction",
  },
  firebaseConfig: {
    apiKey: "AIzaSyA_g2Iw-Ix6jzwKlgu6za524GxetztmIPg",
    authDomain: "mtn-yello-business.firebaseapp.com",
    databaseURL: "https://mtn-yello-business.firebaseio.com",
    projectId: "mtn-yello-business",
    storageBucket: "mtn-yello-business.appspot.com",
    messagingSenderId: "1057593631632",
    appId: "1:1057593631632:web:f9c0dc5b43450582e40915",
    measurementId: "G-BFQ1B0M0RG",
  },
};

const configuration = (): appConfigType => {
  switch (process.env.REACT_APP_CLIENT) {
    case "zamani":
      return zamani;
    case "mtn-benin":
      return mtnBenin;
    case "mtn-zambia":
      return mtnZambia;
    case "base":
      return base;
    default:
      return base;
  }
};
interface appConfigType {
  [key: string]: any;
  sections: {
    [key in "offers" | "news" | "services" | "directory"]: string;
  };
}

export default configuration();
