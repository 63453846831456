import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  useLocation as useRouterLocation,
  useRouteMatch,
} from "react-router-dom";
import { Typography, Tabs, Tab, Toolbar } from "@material-ui/core";
import ArticleForm from "../shared/ArticleForm";
import Notifications from "../shared/Notifications";
import { fetchOne } from "../../API";
import { useUserContext } from "../../authentication";
import Loader from "./Loader";
import TabPanel from "../shared/TabPanel";
import EventChart from "./EventChart";
import appConfig from "../../appConfig";
import { useQuery } from "react-query";
import {FormattedMessage, useIntl} from 'react-intl';

require("moment/locale/fr.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: 100,
      marginBottom: theme.spacing(4),
    },
    paper: {
      width: "70%",
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),

    },
    loader: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    tab: {
      color: theme.palette.tab.main
    }
  })
);

const ArticleEdit = ({
  collection,
}: {
  collection: "offers" | "services" | "stories" | "locationNews";
}) => {
  const intl = useIntl()
  const classes = useStyles();
  const authentication = useUserContext();
  const title = "News d'établissement";

  // ROUTING
  const { params, url } = useRouteMatch<{ id: string }>();

  // DATA QUERY
  const fetchObject = {
    id: params.id,
    include: ["salesperson"],
    fields: {
      salesperson: ["id", "first_name", "last_name","details"],
    },
  };

  const query = useQuery(["articles", fetchObject], () =>
    fetchOne("articles", fetchObject, authentication.accessToken)
  );
  const { status } = query;
  const article = query?.data?.result;

  const valid_firebase_id = article?.firebase_id || article?.previous_firebase_id;

  const [tab, setTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  return (
    <div className={classes.tab}>
      <Toolbar />
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label={`${title} / Édition`} />
        {valid_firebase_id && <Tab label="Statistiques" />}
      </Tabs>
      <TabPanel value={tab} index={0}>
        {status === "loading" && <Loader />}
        {status === "error" && (
          <Typography><FormattedMessage id="ARTICLEEDIT.MSG_ARTNOEXIST" defaultMessage="Cet article n'existe pas"/></Typography>
        )}
        {status === "success" && (
          <>
            <ArticleForm article={article} action={"edit"} />
            <Notifications
              online={article?.displayed_status === "online" ? true : false}
              firebase_id={article?.firebase_id}
              firebase_collection={
                article?.firebase_collection && article.firebase_collection
              }
            />
          </>
        )}
      </TabPanel>
      {article && (
        <TabPanel value={tab} index={1}>
          {valid_firebase_id ? (
            <>
              <EventChart
                eventName="screen_view"
                title={intl.formatMessage({id:'ARTICLEEDIT.TITRE_VISIT',defaultMessage:"Visites"})}
                filter={{
                  dimension: "unifiedScreenName",
                  value: valid_firebase_id,
                }}
              />
              <EventChart
                eventName="screen_view"
                title={intl.formatMessage({id:'ARTICLEEDIT.TITRE_VISIT_UNIQ_JOUR',defaultMessage:"Visiteurs uniques par jour"})}

                filter={{
                  dimension: "unifiedScreenName",
                  value: valid_firebase_id,
                }}
                uniq
              />

              <EventChart
                eventName="article_action"
                title={intl.formatMessage({id:'ARTICLEEDIT.TITRE_CLICBTNACTION',defaultMessage:"Clic sur le bouton action"})}
                filter={{
                  dimension: "customEvent:id",
                  value: valid_firebase_id,
                }}
                extraDim="customEvent:action"
              />
            </>
          ) : (
            <Typography>
              {" "}
              <FormattedMessage id="ARTICLEEDIT.MSG_ARTNONPUBLIER" defaultMessage="Cette article n'a encore jamais été publié."/>
            </Typography>
          )}
        </TabPanel>
      )}
    </div>
  );
};
// ArticleEdit.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default ArticleEdit;
