import React, { useState, useEffect, ChangeEvent } from "react";
import {
  TextField,
  Typography,
  Button,
  Paper,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  useHistory,
  useLocation as useRouterLocation,
  useRouteMatch,
} from "react-router-dom";
import { v4 } from "uuid";
import { useUserContext } from "../../authentication";
import { useImage, uploadImage } from "../../firebase";
import { useMutation, useQueryClient } from "react-query";
import { updateOne, createOne, deletePOST } from "../../API";
import { isNotAuthorized } from "../../utils";
import { Plans } from "../../types/plans";
import SlateEditor from "../shared/SlateEditor";
import { FormattedMessage } from "react-intl";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "70%",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(4),

    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    publishBtn: {
      width: "100%",
    },

    previewImage: {
      maxWidth: 300,
      maxHeight: 180,
      objectFit: "contain",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    buttonWrapper: {
      position: "relative",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    none: {
      display: "none",
    },
    image: {
      display: "flex",
      flexDirection: "column",
    },
    deleteBtnDialog: {
      marginRight: theme.spacing(3),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      width: "100%",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "rgba(244,67,54, 0.2)",
      },
    },
  })
);

const PlanForm = ({
  action,
  plan,
}: {
  plan?: Plans
  action: "edit" | "create";
}) => {
  const intl=useIntl();

  const classes = useStyles();
  const history = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const authentication = useUserContext();
  const { pathname } = useRouterLocation();
  const findPathId = pathname.match(/([1-9][0-9]*)/);


  // value to create or update
  const [value, setValue] = useState<Plans>(
    plan
      ? plan  
      : {
        name: "",
        price_currency: "",
        description: "",
        price_cents: 0,
        id: "",
        position: 0,
        printed_name: "",
        sub_title: ""
      }
  );

  // pour que react-query ait le temps de recevoir l'info après une édition et un retour
  useEffect(() => {
    if (plan) {
      setValue(plan);
    }
  }, [plan]);

  const handleValue = (
    event: any
  ) => {
    // setValue(event.target.value);
    setValue({ ...value, [event.target.name]: event.target.value });
  };





  // Pour l'instant on laisse ça ici (peut-être abstraire)
  const formatValue = (rawValue: any) => {
    const { id, sub_title, description, ...attributes } = rawValue;
    const formattedValue = {
      attributes: {
        ...attributes,
        sub_title: JSON.stringify(sub_title),
        description: JSON.stringify(description),

      },
      ...(id && { id: id }),
    }; 
    return formattedValue;

  };  


  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: any) =>
      action === "edit"
        ? updateOne("plans", formatValue(data), authentication.accessToken)
        : createOne("plans", formatValue(data), authentication.accessToken),
    {
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
      onSuccess: () => {
        setTimeout(() => {
          history.goBack();
        }, 500);
      },
      onSettled: () => {
        // Plutôt que de faire un optimistic update, on clear le cache
        // Histoire que le composant refetch bien la nouvelle data le prochain coup
        // Toutes les queries contenant "articles" vont se faire buter.
        queryClient.invalidateQueries("plans");
      },
    }
  );

  const handlePublish = () => {
    mutation.mutate(value);
  };



  return (
    <>
      <Paper className={classes.paper} elevation={1}>
        <form className={classes.form}>
          <Typography
            className={classes.sectionLabel}
            color="textSecondary"
            variant="h6"
          >
            Plans
          </Typography>

          <TextField
            className={classes.textField}
            value={value.printed_name}
            onChange={(event) => handleValue(event)}
            label="Nom"
            name="printed_name"
          />


          <TextField
            className={classes.textField}
            value={value.price_cents}
            onChange={(event) => handleValue(event)}
            label="Prix"
            name="price_cents"
          />

          <div className={classes.textField}>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage
              id="PLANFORM.CAPTION_SOUSTITRE"
              defaultMessage="Sous-Titre"/>
            </Typography>
            <SlateEditor
              field="sub_title"
              valueArticle={value}
              setValueArticle={setValue}
            />
          </div>

          <div className={classes.textField}>
            <Typography variant="caption" color="textSecondary">
              Description
            </Typography>
            <SlateEditor
              field="description"
              valueArticle={value}
              setValueArticle={setValue}
            />
          </div>

          {/* <TextField
            className={classes.textField}
            label="Position"
            type="number"
            name="position"
            InputLabelProps={{
              shrink: true,
            }}
          /> */}




          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.publishBtn}
              onClick={handlePublish}
            >
              {action === "edit" ? intl.formatMessage({id:'PLANFORM.BTN_ACTION_MODIFPLAN',defaultMessage:"Modifier le plan"}) : intl.formatMessage({id:'PLANFORM.BTN_ACTION_CREER',defaultMessage:"Créer"})}
            </Button>



            {/* {action !== "create" && (
              <Button
                className={classes.deleteBtnDialog}
                variant={"outlined"}
                size="medium"
                color={"default"}
                onClick={() => handleDelete()}
              >
                Supprimer
              </Button>
            )}
            {deleteSucess && (
              <Alert className={classes.publishBtn} severity="success">
                La suppression a été enregistrée
              </Alert>
            )} */}
          </div>
        </form>
      </Paper>
    </>
  );
};

export default PlanForm;
