import React, { useEffect, useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    datePicker: {
      width: "45%",
    },
  })
);

type Props = {
  format?: "weeks_from_start" | "limits";
  date_start: any;
  date_end: any;
  handleEndDateChange: (date: any) => void;
  handleStartDateChange: (date: any) => void;
  limitationFormat?: "none" | "happened" | "will_happen" | "is_happening";
};

const DateForm = ({
  date_start,
  date_end,
  handleStartDateChange,
  handleEndDateChange,
  format = "limits",
  limitationFormat = "none"
}: Props) => {
  const classes = useStyles();
  // used for weeks calculation
  const allready_past_weeks = moment().diff(moment(date_start), "weeks")
  const [weeks, setWeeks] = useState(
    format === "weeks_from_start" ? moment(date_end).diff(moment(date_start), "weeks") : 0
  );
  useEffect(() => {
    if (weeks) handleEndDateChange(moment(moment(date_start)).add(weeks, "weeks"));
  }, [weeks]);

  return (
    <MuiPickersUtilsProvider locale={appConfig.locale} utils={MomentUtils}>
      <div className={classes.date}>
        <KeyboardDatePicker
          disabled={["is_happening", "happened"].includes(limitationFormat)}
          disablePast={limitationFormat === "will_happen"}
          className={classes.datePicker}
          margin="normal"
          id="starting-date-picker-dialog"
          label={
            <FormattedMessage
            id="LOCATIONSFORM.DATE_DEB"  
            defaultMessage="Date de début"/>}
          format="ll"
          value={date_start}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        {format === "limits" && (
          <KeyboardDatePicker
            disabled={limitationFormat === "happened"}
            disablePast={["is_happening", "will_happen"].includes(limitationFormat)}
            className={classes.datePicker}
            margin="normal"
            id="ending-date-picker-dialog"
            label={
              <FormattedMessage
              id="LOCATIONSFORM.DATE_FIN"
              defaultMessage="Date de fin"/>}
            format="ll"
            value={date_end}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        )}
        {format === "weeks_from_start" && (
          <TextField
            disabled={limitationFormat === "happened"}
            margin="normal"
            inputProps={{min: limitationFormat === "is_happening" ? allready_past_weeks : 0, max: 52}}
            id="outlined-number"
            label="Semaines d'activité"
            type="number"
            value={weeks}
            onChange={(e) => setWeeks(parseInt(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DateForm;
