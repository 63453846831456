import React, { useState } from "react";
import {
  Button,
} from "@material-ui/core";
import TabPanel from "../shared/TabPanel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { useUserContext } from "../../authentication";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SortingRule } from "react-table";
import { getSortValue, isNotAuthorized } from "../../utils";
import { useQuery } from "react-query";
import { fetchList } from "../../API";
import Table from "../Table";
import TableActionBar from "../TableActionBar";
import { Salesperson } from "../../types/salesperson";
import AddIcon from "@material-ui/icons/Add";
import TableLink from "../Table/TableLink";
import { User } from "../../types";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    marginB: {
      marginBottom: theme.spacing(4),
    },
    spinner: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    largeDivider: {
      width: "80vw",
    },
    paper: {
      width: "100%",
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    textField: {
      marginTop: theme.spacing(1),
    },
    form: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
    },
    sectionLabel: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    publishBtn: {
      marginTop: theme.spacing(4),
      width: "50%",
    },
    tableau: {
      marginTop: theme.spacing(6),
    },
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    secondTitle: {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    alert: {
      marginTop: theme.spacing(2),
      width: "50%",
    },
    alertEdit: {
      width: "100%",
    },
  })
);


export interface AdminPreview extends Partial<User> {
  email: string;
  id: string;
}

function AdministratorTable() {
  const classes = useStyles();
  const authentication = useUserContext();
  const [tab, setTab] = React.useState(0);
  const { url, path } = useRouteMatch();
  const { state } = useLocation<{
    sortBy: SortingRule<object>[];
    pageSize: number;
    pageIndex: number;
    searchWith: null | string;
  } | null>();

  const pageIndex = state?.pageIndex ? state.pageIndex : 0;
  const pageSize = state?.pageSize ? state.pageSize : 10;
  const sortBy = state?.sortBy
    ? state.sortBy
    : [{ id: "email", desc: false }];
  const searchWith = state?.searchWith ? state.searchWith : "";

  const [controlledSearchWith, setControlledSearchWith] = useState(searchWith);

  const actionBar = (
    <TableActionBar
      title="Administrateurs"
      // value={controlledSearchWith}
      // setValue={(p) => setControlledSearchWith(p)}
    >
      <Button
        variant="contained"
        color="secondary"
        endIcon={<AddIcon />}
        onClick={() => {
          history.push(`administrators/create`, {
            action: "create",
          });
        }}
      >
        <FormattedMessage
        id="ADMINISTRATORTABLE.BTN_CREER"
        defaultMessage="Créer"/>
      </Button>
    </TableActionBar>
  );


  React.useEffect(() => {
  }, [sortBy])
  // COMPONENT STATE
  // État lié au composant (indépendant de la navigation)
  // const [selectionList, setSelectionList] = useState<string[]>([]);
  // const [isSelectionNegative, setIsSelectionNegative] = useState(false);

  const history = useHistory();

  // DATA
  // définition des headers
  const columns = [
    { accessor: "email", Header: "Email", width: "100" },
    { accessor: "id", Header: "Id" },

  ];

  // REQUEST (à partir des états et de columns)
  const fetchObject = {
    page: {
      number: pageIndex + 1,
      size: pageSize,
    },
    fields: { users: ["email", "id"] },
    filters: {
      // ...(searchWith && searchWith.length > 2 && { search: searchWith }),
      admin: true
    },
    sort: getSortValue(sortBy[0], columns),
  };



  const { data, status, isPreviousData } = useQuery<{
    results: AdminPreview[];
    pageCount: number;
    total: number;
  }>(
    ["users", fetchObject],
    () => fetchList("users", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  const transformedData = React.useMemo(() => {
    if (data?.results) {
      return data.results.map((admin: AdminPreview) => ({
        id: admin.id,
        email: (
          <TableLink
            onClick={() => {
              history.push(`administrators/${admin.id}`, { action: "edit" });
              // history.push(`salespeople/${admin.id}`, { action: "edit" });
            }}
          >
            {admin.email}
            {/* {`${admin.last_name} ${admin.first_name}`} */}
          </TableLink>
        ),
      }));
    } else {
      return [];
    }
  }, [data, history, path]);
  // pagination
  const pageCount =
    status === "success" && data?.pageCount ? data.pageCount : 0;
  const totalElements = status === "success" ? data?.total : undefined;

  return (
    <>
    {/* <AdminTab /> */}
      <div className={classes.paper}>
        <Table
          data={transformedData}
          columns={columns}
          initialPageIndex={pageIndex}
          initialPageSize={pageSize}
          initialSortBy={sortBy}
          pageCount={pageCount}
          totalElements={totalElements}
          actionBar={actionBar}
          searchWith={controlledSearchWith}
          isFetching={isPreviousData}
        />
      </div>
    </>
  );
}

export default AdministratorTable;
