import React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { SortingRule } from "react-table";
import { fetchList } from "../../API";
import { useUserContext } from "../../authentication";
import { Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Table from "../Table";
import ColoredTag from "../shared/ColoredTag";
import { getSortValue, isNotAuthorized } from "../../utils";
import { useQuery } from "react-query";
import { Category } from "../../types";
import TableActionBar from "../TableActionBar";
import AddIcon from "@material-ui/icons/Add";
import TableLink from "../Table/TableLink";
import { FormattedMessage } from "react-intl";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    spinner: {
      position: "absolute",
      left: "50%",
      bottom: "50%",
    },
    link: {
      // fontWeight: 500,
      // cursor: "pointer",
    },
  })
);

function CategoriesTable() {
  const classes = useStyles();
  const authentication = useUserContext();
  // ROUTING
  const { path } = useRouteMatch();
  const { state } = useLocation<{
    sortBy: SortingRule<object>[];
    pageSize: number;
    pageIndex: number;
  } | null>();
  // NAVIGATION STATE
  // Les éléments liés à la navigation sont tiré de l'état de category
  // Où ils sont directement définis par Table || ou par un Link / goBack() etc avec state
  const pageIndex = state?.pageIndex ? state.pageIndex : 0;
  const pageSize = state?.pageSize ? state.pageSize : 10;
  const sortBy = state?.sortBy ? state.sortBy : [];

  // COMPONENT STATE
  // État lié au composant (indépendant de la navigation)
  // const [selectionList, setSelectionList] = useState<string[]>([]);
  // const [isSelectionNegative, setIsSelectionNegative] = useState(false);

  const history = useHistory();

  // DATA
  // définition des headers
  const columns = [
    { accessor: "name", Header: "Nom", width: "100" },
    {
      accessor: "position",
      Header: "Position",
    },
    {
      accessor: "directory_home",
      Header: "Page d'Accueil",

    },
    {
      accessor: "online_locations",
      Header: "Etablissements",
      disableSortBy: true,
    },

    {
      accessor: "id",
      Header: "Id",
    },
  ];

  // REQUEST (à partir des états et de columns)
  const fetchObject = {
    page: {
      number: pageIndex + 1,
      size: pageSize,
    },
    // filters: {firebase_collection: "Categories"},
    ...(sortBy[0] && { sort: getSortValue(sortBy[0], columns) }),
  };

  interface CategoryPreview extends Partial<Category> {
    name: string;
    id: string;
    position: number;
    directory_home: boolean;
    online_locations: number;
    }

  const { data, status, isPreviousData } = useQuery<{
    results: CategoryPreview[];
    pageCount: number;
    total: number;
  }>(
    ["categories", fetchObject],
    () => fetchList("categories", fetchObject, authentication.accessToken),
    {
      keepPreviousData: true,
      onError: (e: any) => {
        isNotAuthorized(e) && authentication.logOut();
      },
    }
  );

  const transformedData = React.useMemo(() => {
    if (data?.results) {
      return data.results.map((category: CategoryPreview) => ({
        name: (
          <TableLink
            onClick={() => {
              history.push(`categories/${category.id}`, { action: "edit" });
            }}
          >
            {category.name ? category.name : "sans nom"}
          </TableLink>
        ),
        directory_home: category.directory_home ? (
          <ColoredTag value="present" />
        ) : (
          <ColoredTag value="absent" />
        ),

        position: category.position,

        online_locations: category.online_locations,

        id: category.id,
      }));
    } else {
      return [];
    }
  }, [data]);
  // pagination
  const pageCount =
    status === "success" && data?.pageCount ? data.pageCount : 0;
  const totalElements = status === "success" ? data?.total : undefined;

  const actionBar = (
    <TableActionBar title="Catégories">
      <Button
        variant="contained"
        color="secondary"
        endIcon={<AddIcon />}
        onClick={() => {
          history.push(`categories/create`, {
            action: "create",
          });
        }}
      >
        <FormattedMessage
        id="CATEGORIESTABLE.BTN_CREER"
        defaultMessage="Créer"/>
      </Button>
    </TableActionBar>
  );

  return (
    <div>
      <Table
        data={transformedData}
        columns={columns}
        initialPageIndex={pageIndex}
        initialPageSize={pageSize}
        initialSortBy={sortBy}
        pageCount={pageCount}
        totalElements={totalElements}
        actionBar={actionBar}
        isFetching={isPreviousData}
      />
    </div>
  );
}

export default CategoriesTable;
